.activity-list
  table
    tbody
      tr
        td.creator
          width: 250px
        td.type
          width: 200px
        td.status
          width: 70px
        td.buttons
          width: 170px
          text-align: right
          .btn
            margin-right: 5px

.activity-details
  h1, h4
    margin: 0
    font-weight: 800
    small
      position: relative
      top: -5px
      left: 5px
  .banner
    object-fit: cover
    width: 100%
    max-height: 300px
  form.form-inline
    .form-group
      label,
      input,
      button
        margin-right: 5px

@media (min-width: 768px)
  .activity-details
    form.form-inline
      .form-group
        input
          width: 610px

.activity-admin-dash,
.activity-bg-check-dash
  h1
    .label
      position: relative
      font-size: 0.4em
      top: -5px
      left: 10px
      &.label-black
        background-color: #111
  table.table
    tbody
      tr
        td.name
          min-width: 100px
        td.note
          width: 350px
        td.status
          width: 160px
        td.actions
          width: 110px
          button
            width: 90px
            margin-bottom: 3px
