.route-list
  form
    padding-bottom 15px
    border-bottom 1px solid #eee
    .form-group
      margin-right 5px
    .btn-group
      margin-left 10px
  table
    tbody
      tr
        td.warn
          font-size 14px
          color orange
        td.warn-danger
          font-size 14px
          color red
        td.user
          div
            .details
              font-size 12px
            .dates
              font-size 10px
        td.location
          div
            font-size 12px
        td.gens
          font-size 10px
          text-align right
          width 140px
        td.buttons
          width 90px
          a,
          button
            width 100%
            margin-bottom 5px

.route-details
  hr
    margin 5px 0
  .buttons
    button
      margin-right: 3px
  .tab-pane
    margin-top 10px
  .bottom-bar
    margin-top 10px
    button
      margin-right 3px
    .btn-group
      margin-right 3px
      button
        margin: 0
  p
    span.separator
      margin: 0 3px
      border-left: 1px solid #eee
  .warnings,
  .matches
    max-height: 465px
    overflow-y auto
    td.action
      width: 50px
    .go-btn
      text-align: right
      margin-right: 5px

.route-analysis
  .well
    & > button
      margin 3px 0
  .buttons
    margin-top: 10px
    button
      margin 3px 0 0 3px
    .gmaps
      float: right
      margin-top: 3px
    @media screen and (max-width: 768px)
      .gmaps
        float: none
        margin-top: none
  .price-distance
    display: inline-block
    position: relative
    top: 10px
    left: 10px
  .bottom-bar
    margin-top 10px
    button
      margin-right 3px
  .legend
    margin-top 10px
  .block
    margin 3px 0
    .box
      position relative
      left 5px
      top 6px
      width 15px
      height 15px
      margin 3px 0
      display inline-block
      border 1px solid gray
    .label
      margin-left 10px
    .orange
      background orange
    .red
      background red
    .blue
      background blue

ul
  .dropdown-menu
    li
      a
        cursor pointer

.route-days
  display: inline-block
  position: relative
  top: 10px
  font-size: 12px
  span
    border: 1px solid #eee
    padding: 3px
  div.driving
    margin-bottom: 5px
  span.driving
    color: #fff
    background-color: #337ab7
  span.riding
    color: #fff
    background-color: #5cb85c
  span.time
    margin-left: 5px
    border-radius: 3px
