.major-dest-list
  table
    tbody
      tr
        td.name
          font-weight: bold
        td.date
          width: 150px
        td.status
          width: 130px
        td.buttons
          width: 110px
          text-align: right
          .btn
            margin-right: 5px
