.partner-info
  .well
    background-color: white
    color: #7f7f7f
    ol
      font-size: 30px
      li
        color: #5dade2 
        span
          font-size: 24px
          color: #7f7f7f

.partner-list
  table
    tbody
      tr
        td.name
          font-weight: bold
        td.date
          width: 150px
        td.status
          width: 130px
        td.buttons
          width: 110px
          text-align: right
          .btn
            margin-right: 5px

.partner-dashboard
  .badge
    margin-left: 5px
  .row.header
    margin-bottom: 15px
    padding-bottom: 5px
    border-bottom: 1px solid #eee
    h3
      margin-top: 10px
      span
        margin-left: 3px
    a.selected
      font-weight: bold
  .routes
    max-height: 450px
    overflow-y: auto
    table
      tbody
        tr
          td.selected
            font-weight: bold
          td.name
            img
              width: 50px
              height: 50px
            span
              margin-left: 5px
          td.action
            width: 50px
            vertical-align: middle
  .leaderboards
    max-height: 450px
    overflow-y: auto
    table
      tbody,
      thead
        tr
          td.name,
          th.name
            width: 75%
          td.points,
          th.points
            text-align: left
