.user-list
  form
    padding-bottom 15px
    border-bottom: 1px solid #eee
    .form-group
      margin-right: 5px
    .btn-group
      margin-left: 10px
  table
    tbody
      tr
        td.user
          div.details
            font-size: 12px
            a
              padding-right: 3px
              margin-right: 2px
              border-right: 1px solid gray
          div.dates
            font-size: 10px
        td.image
          img
            width: 100px
        td.buttons
          width: 90px
          a
            width: 100%
            margin-bottom: 5px

.user-details
  .details
    .sep
      padding-right: 4px
      margin-right: 4px
      border-right: 1px solid gray
    .call
      .call-form > div
        width: 300px
  .friends
    table
      tbody
        tr
          td
            vertical-align: middle
          td.status
            width: 150px
          td.action
            width: 150px
            button
              width: 140px
              margin-bottom: 5px

.user-avatar
  display: inline-block
  width: 130px
  img
    width: 50px
    height: 50px
  span
    margin: 0 5px
    display: inline-block
    width: 70px
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    vertical-align: middle