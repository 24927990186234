.event-list
  table
    tbody
      tr
        td.creator
          width: 250px
        td.dates
          width: 200px
        td.status
          width: 90px
        td.buttons
          width: 110px
          text-align: right
          .btn
            margin-right: 5px

.event-form
  .dates
    button.btn.btn-sm.btn-primary
      margin-bottom: 10px
    .form-group.isEditing
      margin-top: 30px
      margin-bottom: 30px
    .date-label
      strong
        margin-right: 5px
    .buttons
      text-align: right
      .btn
        margin-right: 5px
  @media screen and (min-width: 768px)
    .uib-timepicker-container
      height: 50px
      .uib-timepicker
        position: relative
        top: -34px

.event-details
  h1
    font-weight: 800
  h3
    margin-top: 0
    font-weight: 800
  h4
    margin: 0
    font-weight: 800
  .banner
    object-fit: cover
    width: 100%
    max-height: 300px
  .dates
    .date
      margin-right: 15px
  .rides
    text-align: right
    h4
      display: inline-block
      margin-right: 5px
  .ride-container
    padding-right: 10px
    .row
      margin-bottom: 5px
      .city
        font-size: 18px
        font-weight: bold
      button
        width: 100px

.event-card
  background-color: #ffffff
  border: 1px solid #e3e3e3
  border-radius: 12px
  -webkit-box-shadow: inset 0 4px 12px rgba(0, 0, 0, 0.12)
  box-shadow: inset 0 4px 12px rgba(0, 0, 0, 0.12)
  .text
    padding: 10px
    h3
      margin: 0
    .date
      color: red
      font-weight: bold

.ride-request-form,
.ride-offer-form
  .dates
    margin: 10px 0
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12
      padding: 0
      padding-right: 5px
  .space-for
    label
      width: 50%
    select
      width: 50%
      display: inline-block
  @media screen and (min-width: 768px)
    .uib-timepicker-container
      height: 50px
      .uib-timepicker
        position: relative
        top: -10px
        margin: 0 auto
