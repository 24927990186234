.message-list
  table
    tbody
      tr
        td.deliveries
          width: 250px
        td.date
          width: 85px
        td.status
          width: 70px
        td.buttons
          width: 110px
          text-align: right
          .btn
            margin-right: 5px

.message-form
  @media screen and (min-width: 768px)
    .uib-timepicker-container
      height: 50px
      .uib-timepicker
        position: relative
        top: -34px
