.leaderboards
  .input-group
    margin-bottom: 10px
    input
      width: 50%
  table
    tbody
      tr
        td.date
          width: 150px
        td.points
          width: 50px
