.place-list
  form
    padding-bottom 15px
    border-bottom 1px solid #eee
    .form-group,
    .btn
      margin-right 5px
  table
    tbody
      tr
        td.rank
          width: 50px
        td.buttons
          width 70px
          .btn
            margin-bottom 5px
