.auth-form
  .bottom-buttons1
    text-align: right
  .bottom-buttons2
    text-align: left
    span
      margin-right: 5px

.terms
  .navbar.navbar-default
    margin-bottom: 0
  .iframe-container
    overflow: hidden
    position: relative
    padding-top: 62%
    margin-bottom: 20px
    iframe
      border: 0
      height: 100%
      left: 0
      position: absolute
      top: 0
      width: 100%
  .iframe-container-4x3
    padding-top: 72%
  .buttons
    text-align: center
    margin-bottom: 20px
