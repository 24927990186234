.rider-book-seat
  h3,
  h4
    font-weight: 800
  h4
    margin-top: 0
  hr
    margin: 10px 0
  .row
    margin-top: 10px
  .top-row
    .rider-info
      text-align: right
      margin-top: 20px
    .route-info
      text-align: right
      margin-top: 10px
  .carpic-row
    img
      object-fit: cover
      width: 100%
      max-height: 300px
  .suggested-place-row
    h4
      margin-top: 10px
      margin-bottom: 0
