.partner-incentive-list
  table
    tbody
      tr
        td.type
          width: 100px
        td.date
          width: 85px
        td.amount
          width: 70px
        td.status
          width: 100px
        td.buttons
          width: 90px
          text-align: right
          .btn
            margin-right: 5px

.partner-incentive-form
  h2
    font-weight: bold
    margin-top: 10px
