body
  margin-bottom: 30px

.navbar-header
  a.navbar-brand
    padding: 0
    img
      height: 50px
      width: 134px

.table > thead > tr > th,
.table > thead > tr > td
  font-weight: bold

.toast-container
  z-index: 1001
.tab-content
  padding-top: 15px
.text-muted
  margin-right: 5px
.buttons
  .btn
    margin-right: 5px

.table-toolbar
  padding-bottom: 10px
  border-bottom: 1px solid #eee
.table-pager
  padding: 10px 0
  border-bottom: 1px solid #eee

h2
  small
    position: relative
    top: -4px
    left: 5px

.checkbox-custom
  display: inline-block
  margin-right: 5px
  padding-right: 5px

.buttons-bar
  margin-bottom: 15px

.overlay-container
  position: relative
  min-height: 80px
  .overlay
    background-color: black
    opacity: 0.1
    top: 0
    left: 0
    right: 0
    bottom: 0
    height: 100%
    position: absolute
    z-index: 1
  .progress-overlay
    position: absolute
    left: 50%

.ng-table
  th
    text-align: left
.ng-table-pagination
  margin-top: 0 !important
  margin-bottom: 0 !important

input.has-error
  border-color: #a94442
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
