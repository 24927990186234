.custom-marker {
  font-size: 10px;
  padding: 3px;
  background: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: #7F7F7F solid 1px;
  min-width: 40px;
  text-align: center;
}

.custom-marker:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 7px 6px 0;
  border-color: #fff transparent;
  display: block;
  width: 0;
  z-index: 1;
  margin-left: -6px;
  bottom: -6px;
  left: 50%;
}

.custom-marker:before {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 7px 6px 0;
  border-color: #7F7F7F transparent;
  display: block;
  width: 0;
  z-index: 0;
  margin-left: -6px;
  bottom: -7px;
  left: 50%;
}

.gmnoprint {
  opacity: 1 !important;
}

.marker-orange {
  background: orange !important;
  color: black;
}
.marker-red {
  background: red;
  color: white;
}
.marker-blue {
  background: blue;
  color: white;
}
